
export function formataCPF(cpf) {
    //retira os caracteres indesejados...
    cpf = cpf.replace(/[^\d]/g, "");
    //realizar a formatação...
    return (cpf) ? cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : '';
}

export function formataCNPJ(cnpj) {
    //retira os caracteres indesejados...
    cnpj = cnpj.replace(/[^\d]/g, "");
    //realizar a formatação...
    return (cnpj) ? cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5") : '';
}

export function limpaCPF(cpf) {
    //retira os caracteres indesejados...
    return cpf.replace(/[^\d]/g, "")
}

export function limpaCNPJ(cnpj) {
    //retira os caracteres indesejados...
    return cnpj.replace(/[^\d]/g, "")
}

export function formataCEP(cep){
    //retira os caracteres indesejados...
    cep = cep.replace(/[^\d]/g, "");
    //realizar a formatação...
    return (cep) ? cep.replace(/(\d{5})(\d{3})/, "$1-$2") : '';
}