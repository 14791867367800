<template>
  <div class="modal-moderar" :class="[modalAtivado]">
    <div class="modal-moderar-principal">
      <div class="centro">
        <span @click="fecharModal"
          ><img src="~@/assets/images/icones/close.png" alt="Fechar"
        /></span>
        <template v-if="!modal.sucesso">
          <h2>{{ modal.mensagemModal }}</h2>
          <div class="default-tabela">
            <div class="tabela-informacoes">
              <div class="tabela-linha tabela-linha-titulo">
                <div class="tabela-linha-cadastro"><p>Data do Cupom</p></div>
                <div class="tabela-linha-nome"><p>Número do Cupom</p></div>
              </div>
              <div
                class="tabela-linha tabela-linha-informacoes"
                v-for="cupom in modal.cuponsModal"
                :key="cupom.id"
              >
                <div class="tabela-linha-cadastro">
                  <p>{{ cupom.dataCadastro | filterData }}</p>
                </div>
                <div class="tabela-linha-nome">
                  <p>{{ cupom.numero }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-moderar-botoes">
            <router-link :to="'./cupons'">
              <button class="btn btn-moderar" @click="fecharModal">Moderar Cupons</button>
            </router-link>
          </div>
        </template>

        <template v-else>
          <h2>Não existem cupons pendentes de moderação.</h2>
          <p>{{ modal.idHistoricoPremiacaoIsntantanea }}</p>
          <div class="modal-moderar-botoes">
            
              <button class="btn bloquear" @click="moderarPremio(modal.usuarioId, modal.idHistoricoPremiacaoIsntantanea, 2)">Reprovar</button>
      
              <button class="btn aprovar" @click="moderarPremio(modal.usuarioId, modal.idHistoricoPremiacaoIsntantanea, 1)">Aprovar</button>

          </div>
        </template>
      </div>
      <div class="opacity"></div>
    </div>
  </div>
</template>

<script>
import { filterData } from "@/utils/date";
import { atualizarStatusPremiacaoInstantanea } from "@/api/premiacao";

export default {
  data() {
    return {
    };
  },
  computed: {
    modal() {
      return this.$store.state.interacoes.modalModerar;
    },
    modalAtivado() {
      return this.modal.modalAtivado ? "active" : "";
    },
  },
  filters: {
    filterData,
  },
  methods: {
    fecharModal() {
      this.$store.commit("interacoes/SET_ALERTA_MODERAR", {
        ativado: false,
        mensagem: "",
        tipo: ""
      });
    },
    moderarPremio(usuarioId, idHistoricoPremiacaoIsntantanea, idStatusPremiacao) {
      return atualizarStatusPremiacaoInstantanea(usuarioId, idHistoricoPremiacaoIsntantanea, idStatusPremiacao);
    }
  },
};
</script>

<style lang="scss" scoped>
.default-tabela {
  width: 100%;
  overflow-y: auto;
  .tabela-informacoes {
    height: 350px;
  }
}
.opacity {
  width: 100%;
  position: absolute;
  background: #000;
  height: 112vh;
  opacity: 0.8;
  top: 0;
  left: 0;
}
.modal-moderar {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  visibility: hidden;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  transition: 0.3s;
  transform: rotateX(90deg);
  opacity: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  &.active {
    visibility: visible;
    transform: rotateX(0deg);
    opacity: 1;
    height: 100vh;
  }
  h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2em;
    text-align: center;
  }
  a {
    text-decoration: none;
  }
}
.modal-moderar-principal {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .centro {
    position: relative;
    background: white;
    position: relative;
    z-index: 1;
    margin: auto;
    padding: 20px;
    border-radius: 10px;
    max-height: 80%;
    span {
      position: absolute;
      width: 32px;
      height: 32px;
      top: -35px;
      right: 3px;
      align-items: center;
      display: flex;
      justify-content: center;
      cursor: pointer;
    }
    textarea {
      margin: 15px 0px;
      border: 1px solid $neutro4;
      resize: none;
    }
    .modal-moderar-botoes {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      margin-top: 15px;
      button {
        border: none;
        color: #fff;
        height: 30px;
        border-radius: 15px;
        font-size: 18px;
        margin: 0 10px
      }
      .bloquear {
        background: red;
      }
      .aprovar {
        background: green;
      }
    }
  }
}
.btn-moderar {
  max-width: 200px;
  text-transform: none !important;
  color: #fff;
  background: #003169;
  font-size: 18px;
  height: 35px;
  padding: 0 15px;
}
</style>
